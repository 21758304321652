@media screen and (min-width: 769px) {
  section.hero.is-fullheight.is-fullwidth {
    padding-right: 5rem !important;
  }
}

@media screen and (min-width: 769px), print {
  .hero-body {
    padding-right: 15rem !important;
  }
}

.logo-favori {
  text-align-last: center;
}

img.logo {
  width: 80px;
}

.title-app {
  text-align: center;
  margin-bottom: 20px;
}

h1.title.is-2.login {
  text-align-last: center;
}
.hero.is-fullheight .hero-body {
  align-items: start;
}

.hero-body {
  padding: 1rem 1.5rem;
}
