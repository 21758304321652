html {
  background-color: #f8f8f8;
}
.menu-list a.is-active {
  background-color: #282c33;
  color: #fff;
}
img.logo-sidebar {
  max-width: 30px;
  margin-top: 11px;
}
.aside-tools {
  line-height: 4.25rem;
}

select.select-filter {
  border: none;
  background: white;
  margin-top: 2px;
}

.filter-table {
  display: flex;
  margin-top: 5px;
}

thead {
  background-color: #e9e9e9 !important;
}

@media screen and (min-width: 1024px) {
  section.section.is-main-section.print {
    /* padding-right: 14rem; */
    margin-right: 14rem;
  }
}
